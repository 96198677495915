import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user-list"}},[_c('news-category-list-add-new',{attrs:{"data":_vm.selectedNewsCategory},on:{"refetch-data":_vm.fetchItems},model:{value:(_vm.isAddNewSidebarActive),callback:function ($$v) {_vm.isAddNewSidebarActive=$$v},expression:"isAddNewSidebarActive"}}),_c(VRow,{staticClass:"mb-5"}),_c(VCard,[_c(VCardTitle,[_c(VRow,[_c(VCol,[_vm._v(" Search & Filter ")]),_c(VCol,{staticClass:"pb-0",attrs:{"align":"end"}},[(_vm.$can('create', 'NewsCategory'))?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"fab":"","x-small":"","elevation":"4","color":"primary"},on:{"click":function($event){_vm.isAddNewSidebarActive = true}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1)]}}],null,false,56580859)},[_c('span',[_vm._v("Add New News Category")])]):_vm._e()],1)],1)],1),_c('vuex-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.newsCategories,"meta":_vm.meta,"options":_vm.options,"search":_vm.search,"loading":_vm.loading,"footer-props":{
        'items-per-page-options': [5, 10, 25, 50, 100]
      }},on:{"update:meta":function($event){_vm.meta=$event},"update:search":function($event){_vm.search=$event},"update:options":function (o) { return _vm.options = o; }},scopedSlots:_vm._u([{key:"item.articlesCount",fn:function(ref){
      var item = ref.item;
return [(_vm.$can('view', 'News'))?_c('router-link',{staticClass:"d-flex align-center text-decoration-none",attrs:{"to":{
            name: 'news-list',
            query: { newsCategoryId: item.id }
          }}},[_vm._v(" "+_vm._s(item.articlesCount)+" ")]):[_vm._v(" "+_vm._s(item.articlesCount)+" ")]]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [(_vm.$can('update', 'NewsCategory'))?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"me-1",attrs:{"icon":"","small":""},on:{"click":function () { return _vm.selectNewsCategoryToEdit(item); }}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit News Category")])]):_vm._e(),(_vm.$can('destroy', 'NewsCategory'))?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"error","disabled":_vm.deleting,"loading":_vm.isDialogOpen},on:{"click":function($event){return _vm.selectNewsCategoryToDelete(item)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete News Category")])]):_vm._e()]}}],null,true)})],1),(_vm.$can('destroy', 'News'))?_c('news-category-delete-dialog',{attrs:{"data":_vm.selectedNewsCategory,"deleting":_vm.deleting,"is-dialog-open":_vm.isDialogOpen},on:{"update:isDialogOpen":function($event){_vm.isDialogOpen=$event},"update:is-dialog-open":function($event){_vm.isDialogOpen=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }