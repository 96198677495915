<template>
  <v-navigation-drawer
    :value="isAddNewSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    @input="(val) => $emit('update:is-add-new-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">
          {{ t('Add New News Category') }}
        </span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-add-new-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form
          ref="form"
          @submit.prevent="onSubmit"
        >
          <v-text-field
            name="name"
            :value="data && data.name"
            :label="t('Name')"
            :rules="[validators.required]"
            :error-messages="errorMessages.name"
            aria-autocomplete="off"
            autocomplete="off"
          ></v-text-field>

          <v-btn
            :loading="loading"
            color="primary"
            class="me-3"
            type="submit"
          >
            <span v-if="data && data.name">
              {{ t('Edit') }}
            </span>
            <span v-else>
              {{ t('Add') }}
            </span>
          </v-btn>
          <v-btn
            :disabled="loading"
            color="secondary"
            type="reset"
            @click="resetForm"
          >
            {{ t('Cancel') }}
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mdiClose } from '@mdi/js'
// eslint-disable-next-line no-unused-vars
import { ref, getCurrentInstance, computed } from '@vue/composition-api'
// eslint-disable-next-line no-unused-vars, object-curly-newline
import { required, emailValidator, urlValidator, integerValidator } from '@core/utils/validation'

import { useUtils } from '@core/libs/i18n'

export default {
  model: {
    prop: 'isAddNewSidebarActive',
    event: 'update:is-add-new-sidebar-active',
  },
  props: {
    isAddNewSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: false,
      default: () => (null),
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy
    const { t } = useUtils()

    const form = ref(null)
    const errorMessages = ref([])

    const loading = computed({
      get: () => props.data === undefined
        ? vm.$store.getters['newsCategories/creating']
        : vm.$store.getters['newsCategories/updating'],
    })

    const resetForm = () => {
      form.value.reset()
      form.value.resetErrorBag()
      form.value.resetValidation()

      errorMessages.value = []

      emit('update:is-add-new-sidebar-active', false)
    }

    const onSubmit = () => {
      const isFormValid = form.value.validate()
      if (!isFormValid) return

      const formData = new FormData(form.value.$el)

      vm.$store
        .dispatch(
          !props.data
            ? 'newsCategories/create'
            : 'newsCategories/update',
          !props.data
            ? formData
            : { id: props.data.id, formData },
        )
        .then(res => res && resetForm())
        .catch(err => {
          if (err?.data?.errors) {
            errorMessages.value = err.data.errors
          }
        })
    }

    return {
      form,
      loading,
      errorMessages,

      onSubmit,
      resetForm,

      validators: {
        required,
        urlValidator,
        emailValidator,
        integerValidator,
      },

      icons: {
        mdiClose,
      },

      t,
    }
  },
}
</script>
