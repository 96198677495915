<template>
  <div id="user-list">
    <news-category-list-add-new
      v-model="isAddNewSidebarActive"
      :data="selectedNewsCategory"
      @refetch-data="fetchItems"
    ></news-category-list-add-new>

    <v-row class="mb-5"></v-row>

    <!-- list filters -->
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            Search &amp; Filter
          </v-col>

          <v-col
            align="end"
            class="pb-0"
          >
            <v-tooltip
              v-if="$can('create', 'NewsCategory')"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  fab
                  x-small
                  elevation="4"
                  color="primary"
                  @click="isAddNewSidebarActive = true"
                  v-on="on"
                >
                  <v-icon>
                    {{ icons.mdiPlus }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Add New News Category</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-title>

      <!-- table -->
      <vuex-table
        :headers="tableColumns"
        :items="newsCategories"
        :meta.sync="meta"
        :options="options"
        :search.sync="search"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [5, 10, 25, 50, 100]
        }"
        @update:options="o => options = o"
      >
        <template #[`item.articlesCount`]="{item}">
          <router-link
            v-if="$can('view', 'News')"
            :to="{
              name: 'news-list',
              query: { newsCategoryId: item.id }
            }"
            class="d-flex align-center text-decoration-none"
          >
            {{ item.articlesCount }}
          </router-link>

          <template v-else>
            {{ item.articlesCount }}
          </template>
        </template>
        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-tooltip
            v-if="$can('update', 'NewsCategory')"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                class="me-1"
                v-bind="attrs"
                @click="() => selectNewsCategoryToEdit(item)"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiPencil }}
                </v-icon>
              </v-btn>
            </template>
            <span>Edit News Category</span>
          </v-tooltip>

          <v-tooltip
            v-if="$can('destroy', 'NewsCategory')"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                color="error"
                v-bind="attrs"
                :disabled="deleting"
                :loading="isDialogOpen"
                @click="selectNewsCategoryToDelete(item)"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Delete News Category</span>
          </v-tooltip>
        </template>
      </vuex-table>
    </v-card>

    <news-category-delete-dialog
      v-if="$can('destroy', 'News')"
      :data="selectedNewsCategory"
      :deleting="deleting"
      :is-dialog-open.sync="isDialogOpen"
    ></news-category-delete-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiDotsVertical, mdiEyeOutline, mdiPlus, mdiPencil, mdiDeleteOutline, mdiExportVariant } from '@mdi/js'

// eslint-disable-next-line object-curly-newline
import { ref, computed, getCurrentInstance, onMounted, onUnmounted } from '@vue/composition-api'

import { avatarText } from '@core/utils/filter'
import useNewsCategoryList from './useNewsCategoryList'
import NewsCategoryListAddNew from './NewsCategoryListAddNew.vue'
import NewsCategoryDeleteDialog from './NewsCategoryDeleteDialog.vue'

export default {
  components: {
    NewsCategoryListAddNew,
    NewsCategoryDeleteDialog,
  },
  setup() {
    const vm = getCurrentInstance().proxy

    const isDialogOpen = ref(false)
    const isAddNewSidebarActive = ref(false)

    const deleting = computed(() => vm.$store.getters['news-category/deleting'])
    const selectedNewsCategory = ref(null)
    const selectNewsCategoryToEdit = newsCategory => {
      selectedNewsCategory.value = newsCategory
      isAddNewSidebarActive.value = !!newsCategory
    }
    const selectNewsCategoryToDelete = newsCategory => {
      selectedNewsCategory.value = newsCategory
      isDialogOpen.value = !!newsCategory
    }
    const {
      newsCategoryListTable,
      tableColumns,
      totalNewsCategoryListTable,
      loading,

      newsCateogryTotalLocal,

      meta,
      search,
      filters,
      options,
      roleFilter,

      setOptions,
      setFilters,

      newsCategories,

      init,
      destroy,
      fetchItems,
    } = useNewsCategoryList()

    onMounted(() => {
      init()
    })
    onUnmounted(() => {
      destroy()
    })

    return {
      newsCategoryListTable,
      tableColumns,
      totalNewsCategoryListTable,

      loading,
      newsCateogryTotalLocal,
      avatarText,

      setOptions,
      setFilters,
      fetchItems,

      newsCategories,
      meta,
      search,
      filters,
      options,
      roleFilter,

      // icons
      icons: {
        mdiPlus,
        mdiPencil,
        mdiEyeOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiExportVariant,
      },

      isDialogOpen,

      deleting,
      selectedNewsCategory,
      selectNewsCategoryToDelete,
      isAddNewSidebarActive,

      selectNewsCategoryToEdit,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
